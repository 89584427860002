require("@fontsource-variable/outfit"); // Defaults to weight 400 with normal variant.
require("./src/styles/global.css");
require("./src/styles/style.css");

// const React = require("react");
// // const  Layout  = require("./src/components/layout").default;

// // exports.wrapPageElement = ({ element, props }) => {
// //   // props provide same data to Layout as Page element will get
// //   // including location, data, etc - you don't need to pass it
// //   return <Layout {...props}>{element}</Layout>;
// // };

// // Ensure client-side redirects work
// exports.onClientEntry = () => {
//   // Add any client-side redirect logic if needed
// }
